import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <Header anchorLinks />
      <main id="main-content">
        <div className="container pt-5">
          <h1 className="h2">Page Not Found</h1>
          <p>Can't find this page, sorry!</p>
          <p>
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in <code>src/pages/</code>.
                <br />
              </>
            ) : null}
            <Link to="/">Go back to home page</Link>.
          </p>
        </div>
      </main>
      <Footer />
    </Layout >
  )
};

export default NotFoundPage;
